// extracted by mini-css-extract-plugin
export var section = "about-module--section--ZbZUm";
export var section_content = "about-module--section_content--2UHw-";
export var section1img = "about-module--section1img--8k2ff";
export var section2description = "about-module--section2description--SKRY-";
export var section2img = "about-module--section2img--JpQDX";
export var section3img = "about-module--section3img--NEqeg";
export var section3mainText = "about-module--section3mainText--imTpJ";
export var section3 = "about-module--section3--i79W8";
export var title = "about-module--title--LaqPm";
export var section3headerInfo = "about-module--section3headerInfo--5VOxi";
export var speach_ceo_name = "about-module--speach_ceo_name--HN7mj";
export var ceo_info = "about-module--ceo_info--NqEr2";
export var section4img = "about-module--section4img--FZGCV";
export var people_card_list = "about-module--people_card_list--g24GL";
export var people_card = "about-module--people_card--axwMN";
export var people_avater = "about-module--people_avater--TCqC1";
export var people_main_info = "about-module--people_main_info--FWHU3";
export var description = "about-module--description--AF7Eq";
export var section3_header = "about-module--section3_header--hS-nn";
export var root = "about-module--root--ozOZn";
export var section1 = "about-module--section1--9-UZQ";
export var section2 = "about-module--section2--QjB-G";
export var seciton3_main_content = "about-module--seciton3_main_content--IAq6n";
export var section4 = "about-module--section4--KDJ1m";
export var section4_left_info = "about-module--section4_left_info--UvFr4";
export var inkedinProfileLink = "about-module--inkedinProfileLink--TuUmz";