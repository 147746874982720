import React from 'react';

import * as classes from '../styles/about.module.scss';
import section1img from '../images/about_section_1.svg';
import section2img from '../images/about_section_2.svg';
import section3img from '../images/about_section_3.svg';
import section4img from '../images/about_section_4.svg';
import Layout from '../components/Layout/Layout';
import linkedInLinkIcon from '../images/linkedInLink.svg';
import Typography from '../components/Typography/Typography';
import { graphql,useStaticQuery} from "gatsby";

import SEO from '../components/Seo';

export default function about() {
  const { allPrismicTeamlist } = useStaticQuery(graphql`
    {
      allPrismicTeamlist {
        edges {
          node {
            data {
              id {
                avatar {
                  url 
                }
                designation {
                  text
                }
                linkedin {
                  url
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

    // Correctly accessing the nested array structure
    const teamMembers = allPrismicTeamlist.edges.flatMap(edge =>
      edge.node.data.id.map(member => ({
        avatar: {
          alt: member.avatar.alt,
          url: member.avatar.url, // Use URL directly as GatsbyImage data is removed
        },
        designation: member.designation,
        linkedin: member.linkedin,
        title: member.title
      }))
    );
if (teamMembers.length === 0) {
  return <div>No team members to display.</div>;
}
  return (
    <Layout>
      <SEO title="About" />
      <div className={classes.root}>
       <div className={classes.main_container}>        
           <div>
            <Typography variant="h1" align="center">
            About Experts Circle
            </Typography>
          </div>
        <section className={`${classes.section} ${classes.section2}`}>
          <div className={classes.section_content}>
            <img className={classes.section2img} src={section2img} alt="About" />
            <p className={`${classes.description} ${classes.section2description}`}>
              <Typography variant="p">
              Experts Circle taps into the vast potential of seasoned professionals across industries, equipping them to use their rich qualifications and networks as recruitment catalysts. 
              This platform revolutionizes job matching, ensuring each candidate is meticulously assessed and endorsed by those who truly comprehend the nuances of the required competencies. 
              Here, not only do employers gain access to preeminent talent swiftly, 
              but our expert recruiters also enjoy the dual benefit of contributing their expertise and earning supplemental income. 
              The majority of the placement fee honors the recruiter's dedication to quality, making it a rewarding journey for every link in the employment chain. </Typography>
            </p>
          </div>
        </section>
        <section className={`${classes.section} ${classes.section3}`}>
          <div className={classes.section_content}>
            <div className={classes.section3_header}>
              <Typography variant="h2">Our Story</Typography>

              <Typography variant="h3" align="center">
                Experts Circle started with the innovative vision and hard-earned experience of our
                founder. Our story is ultimately his story.
              </Typography>
            </div>
            <div className={classes.seciton3_main_content}>
              <img className={classes.section3img} src={section3img} alt="About" />
              <div className={classes.section3mainText}>
                {/* <img src={doubleQuoteImg} alt="double quote" /> */}

                <div className={classes.description}>
                  <Typography variant="p">
                  Experts Circle emerged from a vision to revolutionize recruitment, fueled by our founder Robiul Islam's personal challenges within the industry. Encountering unethical practices and widespread inefficiencies, his experiences as a software engineer in the recruitment technology sector revealed the critical need for reform.
                  </Typography>
                  <Typography variant="p">
                  Determined to foster a new era of integrity and precision in recruitment, Robiul leveraged his technical acumen and deep industry insights to establish Experts Circle. Our platform embodies this transformative vision, uniting advanced technology with expert knowledge to ensure swift, precise, and reliable job matching.
                  </Typography>
                  <Typography variant="p">
                  At Experts Circle, we invite you to join a movement reshaping the recruitment landscape. Together, we are dedicated to providing transparent and effective hiring experiences, championing quality and fairness for employers and job seekers alike. Discover how we're leading change—one successful placement at a time.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${classes.section} ${classes.section4}`}>
          <div className={classes.section_content}>
            <div className={classes.section4_left_info}>
              <Typography variant="h2">Our Mission</Typography>
              <Typography variant="p">
              Our mission at Experts Circle is to transform the traditional recruitment framework. We enable industry specialists to become recruitment catalysts, guiding employers quickly to prime candidates. Each potential hire is rigorously vetted and affirmed by these connoisseurs, ensuring a pairing that's both expedient and dependable.
              </Typography>
            </div>
            <img className={classes.section4img} src={section4img} alt="About" />
          </div>
        </section>
        <seciton className={`${classes.section}`}>
          <Typography variant="h2" align="center">
            Our Team
          </Typography>
          <div className={classes.people_card_list}>
            {teamMembers.map((people) => {
              return (
                <div className={classes.people_card} key={people.id}>
                  <a
                    className={classes.inkedinProfileLink}
                    rel="noreferrer"
                    href={people.linkedin.url}
                    target="_blank"
                  >
                    <img src={linkedInLinkIcon} alt="Linkedin profile" />
                  </a>
                  <div className={classes.people_avater}>
                    <img src={people.avatar.url} alt={people.title.text} />
                  </div>
                  <div className={classes.people_main_info}>
                    <h4>{people.title.text}</h4>
                    <p>{people.designation.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </seciton>
        </div>        
      </div>
    </Layout>
  );
}
